import { create } from 'zustand';

type ModalId = string;

type State = {
  currentModalId: ModalId | null;
  modalData: Record<ModalId, unknown>;
};

type Actions = {
  showModal: <T>(modalId: ModalId, data?: T) => void;
  hideModal: (modalId?: ModalId) => void;
  getModalData: <T>(modalId: ModalId) => T | undefined;
};

type Store = State & {
  actions: Actions;
};

const useModalStore = create<Store>((set, get) => ({
  currentModalId: null,
  modalData: {},

  actions: {
    showModal: (modalId, data) => {
      document.body.style.overflow = 'hidden';
      set((state) => ({
        currentModalId: modalId,
        modalData: { ...state.modalData, [modalId]: data },
      }));
    },
    hideModal: () => {
      document.body.style.overflow = '';
      set(() => ({
        currentModalId: null,
        modalData: {},
      }));
    },
    getModalData: <T>(modalId: ModalId): T | undefined => {
      const data = get().modalData[modalId];
      return data as T | undefined;
    },
  },
}));

export const useModalActions = () => useModalStore((state) => state.actions);
export const useCurrentModalId = () => useModalStore((state) => state.currentModalId);
export const useModalData = <T>(modalId: ModalId): T | undefined => {
  const data = useModalStore((state) => state.modalData[modalId]);
  return data as T | undefined;
};
