'use client';

import {
  isServer,
  matchQuery,
  MutationCache,
  MutationMeta,
  QueryClient,
  QueryClientProvider,
  QueryKey,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ReactNode } from 'react';
import { useToastActions } from '../_components/Toast/_stores/toastStores';

type QueryKeysReturnType = readonly (string | number | readonly (string | number)[])[];

declare module '@tanstack/react-query' {
  interface CustomMutationMeta extends MutationMeta {
    successMessage?: string;
    invalidates?: QueryKeysReturnType;
    errorMessage?: {
      title: string;
      description: string;
    };
  }
}

function makeQueryClient(showToast: (message: string, isSuccess?: boolean) => void) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 1000,
        throwOnError: true,
      },
    },
    mutationCache: new MutationCache({
      onSuccess: (_, __, ___, mutation) => {
        queryClient.invalidateQueries({
          predicate: (query) =>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            mutation?.options?.meta?.invalidates?.some((queryKey) =>
              matchQuery({ queryKey: queryKey as QueryKey }, query),
            ) ?? false,
        });
        showToast(mutation?.options?.meta?.successMessage as string, true);
      },
      // TODO: error 처리관련 정의 필요
      // onError: (error, _, __, mutation) => {
      //   const meta = mutation?.options?.meta;
      //   if (meta?.errorMessage) {
      //     showAlert(meta.errorMessage.title, meta.errorMessage.description, () => {
      //       hideAlert();
      //     });
      //     return;
      //   }

      //   const customError = error as CustomError;
      //   const apiMessage = customError.response?.data?.apiMessage ?? 'DEFAULT_ERROR';
      //   const errorMessage = CustomErrorMap[apiMessage] ?? CustomErrorMap.DEFAULT_ERROR;
      //   showAlert(errorMessage.title, errorMessage.description || '', () => {
      //     hideAlert();
      //   });
      // },
    }),
  });

  return queryClient;
}

let browserQueryClient: QueryClient | undefined = undefined;

function getQueryClient(showToast: (message: string, isSuccess?: boolean) => void) {
  if (isServer) {
    return makeQueryClient(showToast);
  } else {
    if (!browserQueryClient) browserQueryClient = makeQueryClient(showToast);
    return browserQueryClient;
  }
}

export default function QueryClientProviders({ children }: { children: ReactNode }) {
  const { showToast } = useToastActions();
  const queryClient = getQueryClient(showToast);

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
