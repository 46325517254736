import { Loading } from '../loading';

type Props = {
  children: React.ReactNode;
  isLoading: boolean;
  fallback?: React.ReactNode;
  isImmediate?: boolean;
};

export const MySuspense = ({ children, isLoading, fallback = <Loading.공백 /> }: Props) => {
  if (!isLoading) return children;
  return fallback;
};
