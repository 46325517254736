'use client';

import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

export const useInitDatadog = () => {
  const RUM_CONFIG = {
    SESSION_SAMPLE_RATE: 100,
    SESSION_REPLAY_SAMPLE_RATE: 70,
  } as const;

  useEffect(() => {
    try {
      const env = process.env.NEXT_PUBLIC_ENV;
      const appId = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID;
      const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;

      if (env !== 'release' || !appId || !clientToken) {
        return;
      }

      datadogRum.init({
        applicationId: appId,
        clientToken: clientToken,
        site: 'datadoghq.com',
        service: 'academia-platform',
        env: env,
        sessionSampleRate: RUM_CONFIG.SESSION_SAMPLE_RATE,
        sessionReplaySampleRate: RUM_CONFIG.SESSION_REPLAY_SAMPLE_RATE,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        // startSessionReplayRecordingManually: true,
        // beforeSend: (event) => {
        //   if (event.type === 'error') {
        //     // 에러 이벤트 발생 시 세션 리플레이 녹화 시작
        //     datadogRum.startSessionReplayRecording();
        //   }
        //   return true;
        // },
      });
    } catch (error) {
      console.error('Datadog 초기화 실패:', error);
    }
  }, []);
};
