import { create } from 'zustand';

type State = {
  message: string | null;
  isSuccess?: boolean;
};

type Actions = {
  showToast: (message: string, isSuccess?: boolean) => void;
};

type Store = State & {
  actions: Actions;
};

const useToastStore = create<Store>((set) => {
  let hideTimeout: NodeJS.Timeout;
  let resetTimeout: NodeJS.Timeout;

  return {
    message: null,
    isSuccess: undefined, // 초기 성공 상태 설정

    actions: {
      showToast: (message: string, isSuccess?: boolean) => {
        if (hideTimeout) clearTimeout(hideTimeout);
        if (resetTimeout) clearTimeout(resetTimeout);

        set({ message: null, isSuccess: undefined });

        resetTimeout = setTimeout(() => {
          set({ message, isSuccess });

          hideTimeout = setTimeout(() => {
            set({ message: null, isSuccess: undefined });
          }, 5000);
        }, 100);
      },
    },
  };
});

export const useToastActions = () => useToastStore((state) => state.actions);
export const useToastMessage = () =>
  useToastStore((state) => ({
    message: state.message,
    isSuccess: state.isSuccess,
  }));
