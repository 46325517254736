'use client';

import { initCPL } from '@/_global/_logging/initCpl';

let initialized = false;
export const useInitCPL = () => {
  if (!initialized) {
    initCPL();
    initialized = true;
  }
  return null;
};
