import * as S from './runningRtan.css';
import React from 'react';

export const RunningRtan = () => {
  return (
    <div className={S.wrapper}>
      <img
        src={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/rtan/running_rtanny.gif`}
        alt="runningRtan"
        width={59}
        style={{
          transform: 'scaleX(-1)',
          objectFit: 'contain',
        }}
      />
    </div>
  );
};
