import { assignInlineVars } from '@vanilla-extract/dynamic';
import * as S from './spinner.css';
import { neutralDay } from '@/_global/_styles/design-system/foundation/color';

type SpinnerProps = {
  color?: string;
};
export const Spinner = ({ color = neutralDay.gray60 }: SpinnerProps) => {
  return (
    <div className={S.wrapper}>
      <div className={S.spinnerContainer}>
        <div className={S.spinner} style={assignInlineVars({ [S.borderColor]: color })}></div>
      </div>
    </div>
  );
};
