import * as S from './empty.css';
import { narrowHeight, narrowWidth, wideHeight, wideWidth } from './empty.css';
import { assignInlineVars } from '@vanilla-extract/dynamic';

type Size = {
  wide: number;
  narrow: number;
};

type Props = {
  width?: Size;
  height?: Size;
};

export const Empty = ({ width, height }: Props) => {
  return (
    <div
      className={S.EmptyCss}
      style={assignInlineVars({
        [wideWidth]: `${width?.wide}px`,
        [wideHeight]: `${height?.wide}px`,
        [narrowWidth]: `${width?.narrow}px`,
        [narrowHeight]: `${height?.narrow}px`,
      })}
    />
  );
};
