'use client';

import { getUserInfoObject } from '@/_global/_business/_service/cookie.client.service';
import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

export const useInitDatadogUser = () => {
  const userInfo = getUserInfoObject();

  useEffect(() => {
    if (userInfo.name && process.env.NEXT_PUBLIC_ENV === 'release') {
      datadogRum.setUser({
        name: decodeURIComponent(userInfo.name),
        email: decodeURIComponent(userInfo.email),
        phone: decodeURIComponent(userInfo.phone),
      });
    }
  }, [userInfo.name, userInfo.email, userInfo.phone]);
};
