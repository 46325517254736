'use client';
import { createInstance, HackleProvider } from '@hackler/react-sdk';
import { ReactNode } from 'react';
import HackleDevTools from '@hackler/javascript-devtools';
import { getDeviceId } from '@/_global/_providers/HackleProvider/hackleClient.service';
import { getUserId } from '@/_global/_business/_service/cookie.client.service';

const config = {
  debug: process.env.NEXT_PUBLIC_ENV !== 'release',
  auto_track_page_view: true,
  devTool: HackleDevTools,
  autoOpenDevTool: process.env.NEXT_PUBLIC_ENV !== 'release',
};

const hackleClient = createInstance(process.env.NEXT_PUBLIC_HACKLE_SDK_KEY!, config);

function ProvideHackle({ children }: { children: ReactNode }) {
  const user = {
    id: getDeviceId(),
    userId: getUserId(),
    deviceId: getDeviceId(),
  };

  return (
    <HackleProvider hackleClient={hackleClient} supportSSR user={user}>
      {children}
    </HackleProvider>
  );
}

export default ProvideHackle;
