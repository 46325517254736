'use client';
import { useCurrentModalId, useModalActions } from '@/_global/_components/Modal/_stores/modalStore';
import { Button } from '@/_global/_styles/design-system/components/Button/Button';
import { neutralDark, neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { NavigationClose } from '@/_global/_styles/design-system/foundation/icon';
import React, {
  ComponentProps,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useId,
} from 'react';
import ReactDOM from 'react-dom';
import { ModalTheme } from './_types';
import * as S from './modal.css';

const ModalContext = React.createContext<{
  modalId: string;
  showModal: (modalId: string) => void;
  hideModal: (modalId: string) => void;
  isModalOpen: (modalId: string) => boolean;
  setInitialState?: (initialState: Record<string, boolean>) => void;
  isOneButton?: boolean;
  theme?: ModalTheme;
  type?: 'modal' | 'alert';
}>({
  theme: 'dark',
  modalId: '',
  showModal: () => {},
  hideModal: () => {},
  isModalOpen: () => false,
  isOneButton: false,
});

export interface ModalRootProps {
  id?: string;
  theme?: ModalTheme;
  children: ReactNode;
  type?: 'modal' | 'alert';
  isOneButton?: boolean;
}

const ModalRoot = ({ id, type = 'modal', children, isOneButton, theme }: ModalRootProps) => {
  const generatedId = useId();
  const modalId = id || generatedId;
  const { showModal, hideModal } = useModalActions();
  const currentModalId = useCurrentModalId();

  const isModalOpen = () => currentModalId === modalId;

  return (
    <ModalContext.Provider
      value={{ theme, modalId, showModal, hideModal, isModalOpen, type, isOneButton }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export interface ModalProps extends ComponentProps<'div'> {
  theme?: ModalTheme;
  dimmed?: boolean;
  width?: 460 | 640;
  gap?: 20 | 32 | 50;
  fixedHeight?: boolean;
  children?: ReactNode;
  isInitialOpen?: boolean;
  unCloseable?: boolean;
  onClose?: () => void;
}

const ModalComponent = ({
  dimmed = true,
  width = 460,
  gap = 32,
  fixedHeight,
  children,
  isInitialOpen = false,
  unCloseable = false,
  onClose,
  ...props
}: ModalProps) => {
  const {
    modalId,
    hideModal,
    isModalOpen,
    setInitialState,
    showModal,
    type,
    isOneButton,
    theme = 'dark',
  } = useContext(ModalContext);

  useEffect(() => {
    if (setInitialState) {
      setInitialState({ [modalId]: isInitialOpen });
    }
    if (isInitialOpen) {
      showModal(modalId);
    }
  }, [isInitialOpen, modalId, setInitialState, showModal]);

  if (!isModalOpen(modalId)) {
    return null;
  }

  return ReactDOM.createPortal(
    <div>
      <div
        onClick={() => {
          if (!unCloseable) {
            onClose?.();
            hideModal(modalId);
          }
        }}
        className={S.modalDimmed({ dimmed })}
      />
      <div
        {...props}
        className={S.modalWrapper({ theme, gap, width, type, isOneButton, fixedHeight })}
      >
        {children}
      </div>
    </div>,
    document.body,
  );
};

export interface ModalTriggerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const ModalTrigger = ({ children, onClick }: ModalTriggerProps) => {
  const { modalId, showModal } = useContext(ModalContext);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    showModal(modalId);
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <div className={S.modalTrigger} onClick={handleClick}>
      {children}
    </div>
  );
};

export interface ModalHeaderProps {
  headerText?: string;
  title?: string[] | string | React.ReactElement<HTMLSpanElement>;
  subTitle?: string;
  isShowExitIcon?: boolean;
  onClose?: () => void;
  children?: ReactNode;
}

const ModalHeader = ({
  headerText = '',
  title = '',
  subTitle = '',
  isShowExitIcon = false,
  onClose,
  children,
}: ModalHeaderProps) => {
  const { theme = 'dark', modalId, hideModal } = useContext(ModalContext);

  return (
    <>
      {isShowExitIcon && (
        <div
          className={S.modalCloseButton}
          onClick={() => {
            onClose?.();
            hideModal(modalId);
          }}
        >
          <NavigationClose size={24} color={neutralDay.gray60} />
        </div>
      )}
      {title && (
        <div className={S.modalTitleWrapper}>
          <div className={S.modalTitleWrapperInner}>
            {headerText && <div className={S.modalHeaderText}>{headerText}</div>}
            <div style={{ gap: 0 }}>
              {Array.isArray(title) && theme ? (
                title.map((item) => (
                  <div key={item} className={S.modalTitle({ theme })}>
                    {item}
                  </div>
                ))
              ) : (
                <div className={S.modalTitle({ theme })}>{title}</div>
              )}
            </div>
          </div>
          {subTitle && <div className={S.modalSubTitle({ theme })}>{subTitle}</div>}
          {children}
        </div>
      )}
    </>
  );
};

const ModalContent = ({ children, ...props }: PropsWithChildren<ComponentProps<'div'>>) => {
  const { theme = 'dark' } = useContext(ModalContext);
  return (
    <div className={S.modalContentWrapper({ theme })} {...props}>
      {children}
    </div>
  );
};

export interface ModalFooterProps {
  confirmText?: string;
  cancelText?: string;
  isValid?: boolean;
  onConfirm?: (hideModal: (modalId: string) => void, modalId: string) => void;
  onCancel?: (hideModal: (modalId: string) => void, modalId: string) => void;
}

const ModalFooter = ({
  confirmText = '확인',
  cancelText = '취소',
  isValid = true,
  onConfirm = (hideModal, modalId) => {
    hideModal(modalId);
  },
  onCancel = (hideModal, modalId) => {
    hideModal(modalId);
  },
}: ModalFooterProps) => {
  const {
    modalId,
    hideModal,
    type,
    isOneButton = false,
    theme = 'dark',
  } = useContext(ModalContext);
  const buttonWidth = confirmText.length < 6 ? '100px' : 'fit-content';
  const handleConfirmClick = () => {
    onConfirm(hideModal, modalId);
  };

  const handleCancelClick = () => {
    onCancel(hideModal, modalId);
  };
  const renderButtons = () => {
    if (isOneButton) {
      return (
        <Button
          size="lg"
          style={{
            width: '100%',
            alignSelf: 'center',
            whiteSpace: 'nowrap',
          }}
          colorScheme={type === 'modal' ? 'primary' : 'secondary'}
          disabled={!isValid}
          onClick={handleConfirmClick}
        >
          {confirmText}
        </Button>
      );
    } else {
      if (type === 'alert') {
        return (
          <Button
            size="lg"
            style={{
              width: buttonWidth,
              alignSelf: 'flex-end',
              whiteSpace: 'nowrap',
              ...(theme === 'dark' && {
                backgroundColor: neutralDay.white,
                color: neutralDay.gray100,
              }),
            }}
            colorScheme="secondary"
            disabled={!isValid}
            onClick={handleConfirmClick}
          >
            {confirmText}
          </Button>
        );
      } else {
        return (
          <div className={S.modalButtonWrapper}>
            <Button
              onClick={handleCancelClick}
              size="lg"
              colorScheme="tertiary"
              variant="solid"
              style={{
                width: '100%',
                ...(theme === 'dark' && {
                  backgroundColor: neutralDark.gray30,
                  color: neutralDay.gray10,
                }),
              }}
            >
              {cancelText}
            </Button>
            <Button
              onClick={handleConfirmClick}
              size="lg"
              colorScheme="primary"
              variant="solid"
              style={{ width: '100%' }}
              disabled={!isValid}
            >
              {confirmText}
            </Button>
          </div>
        );
      }
    }
  };

  return renderButtons();
};

const Modal = {
  Root: ModalRoot,
  Trigger: ModalTrigger,
  Wrapper: ModalComponent,
  Header: ModalHeader,
  Content: ModalContent,
  Footer: ModalFooter,
};

export { Modal };
