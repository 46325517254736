'use client';
import React from 'react';
import { toast } from '@/_global/_components/Toast/toast.css';
import { useToastMessage } from '@/_global/_components/Toast/_stores/toastStores';
import { InfoPlayCircle } from '@/_global/_styles/design-system/foundation/icon';
import { additional } from '@/_global/_styles/design-system/foundation/color';

const Toast = () => {
  const { message, isSuccess } = useToastMessage();

  if (!message) return null;

  return (
    <div className={toast}>
      {isSuccess ? <InfoPlayCircle style={'fill'} size={24} color={additional.green80} /> : null}
      {message}
    </div>
  );
};

export default Toast;
