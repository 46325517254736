'use client';
import { useInitCPL } from '@/_global/_business/_hooks/useInitCPL';
import { useInitDatadog } from '@/_global/_business/_hooks/useInitDatadog';
import { useInitDatadogUser } from '@/_global/_business/_hooks/useInitDatadogUser';

export const InitClient = () => {
  useInitCPL();
  useInitDatadog();
  useInitDatadogUser();
  return null;
};
