import "../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.9_@types+node@20.12.12_babel-plugin-macros@3.1.0_terser@5_ssdhf6ncur7qs77olroqacjhfa/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22app%2F_global%2F_styles%2Fdesign-system%2Ffont%2Ffont.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA8XWS26DMBAG4D2n8AUS%2BQUh5BK9QUWKCW7BJICUpFXvXvWRbjKLMT9StiNLn8aPf7x%2BVsHtVe%2Bl%2BEiEqPswrcbp2rpChH7oynZ3q56dPzRTITZS%2FtdG%2F%2B4KYeXx8l1qfXCr5m9Zmv0Wf9bVZefbayGeBje5UJVDtUs%2Bk%2FXNVvNtownbpnxbc%2B3sztaWsI3l2wawqT3XEXtuubZl2jnfTuf3rXLK3vLtbH7fsL0B%2Bs7A886BvlF7y7XTe5t6Y1rz7RLoG7X3gG0oW%2FLtF8BWhK0izrt64D13XNssb9fz55imssVEZMsBsNE51gA2Okv80rMk4u%2FwuvQ9j8iWt6XzPMJugb7RTO0emOcBmGNkniu%2B3S89SyLsI9A39T9XEe%2F7BPSN2gNgU7mmIrJlBN5YSp13RJ5PwAydZ38BPww%2BjocOAAA%3D%22%7D"
import "../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.9_@types+node@20.12.12_babel-plugin-macros@3.1.0_terser@5_ssdhf6ncur7qs77olroqacjhfa/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22app%2F(_pages)%2F(withoutGnb)%2Flesson%2F%5BenrollmentId%5D%2F_styles%2Flayout.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA6VSTY%2FaMBS88yuetBeQ1tThIxRzaSu15%2F6Fl%2FiFWDi25RgSWvHfqyTOAtWCqq18ynjem8mM5yVtkw3n8HsCUOfeap2hZ7nV1gt4Wf9I0%2FQb%2BH2GU%2F7anzmf7e64jZKhFBBKZXaTy2QeNwrBGsoOKrA3ai8S6Unq2m5PSWpfBgGfu8%2Bn0yx4zA%2F9jgzzw97bo5Gj03ccZtZL8syjVMdaQML%2FQaE8VtkDhZjFw833M1o5Abk1gUxgmW2vYwLWroXaaiUheDS1Q08m3FhLegeFNYHV4axJgLG%2BQr0b0SZmtuH8DavVLxKwWA1WtDLExmiXEex5BVZKnwX89BTISPSyU%2F5SkVTYlUpkAI2EaYXtWO1qu3XtrHd1Z%2FFaJuenZtcjo2jCuTyVAyZV7TSeBey9kgPkbK2CskaAJ41BnWjAOwYLVDmNgZi3TS1gzV0L65VrISk8cEjj%2F%2FzNzq0%2BVqYrpPDxHp2AZGTbE%2FlC20ZAqaQkM6DX1gS8LL8uv6%2BSaBGlVGbPMhuCrQSQOU1rLIihJ2TK1BTiXf%2FcLtd0FjGd3p7vBBP4BIsbz4PVG%2FzyoARlxhLWnH%2BkhCcdvJd1unAtbBZD1k9DrpSpsJ2ulolrXzv2DPAY7H8H%2F%2BEclzHHP%2BlmChzTBAAA%22%7D"
import "../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.9_@types+node@20.12.12_babel-plugin-macros@3.1.0_terser@5_ssdhf6ncur7qs77olroqacjhfa/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22app%2F_global%2F_components%2FModal%2Fmodal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA61WXW%2FjKhB9768Y6apSV1oq7DiuQ192V2qe718gBidsMHgBN85e9b9fGX8Ep06avb1VlQTOMMwczgw82qN5pRGGf%2B4AKm2FE1oRKETD2fMdgNMVAdz%2Bkrxw%2Fc%2BDYG5HIML4vh3uuNju3Gm8ofl%2Ba3StGMq11IaA2W7oA%2F4K%2Ff%2Fj8ktr9xsJxXhDYLF6vnu7e%2BxDIYXOa%2BsD0rWTQnECSise2EQevW2fcVF8YZEzVNmKGq5cYL24ysiyy7TjpB8wYStJjwQKyZt2ov1GTBiedy5yLetShYwVwqFcK%2Be3BqgoY0JtCSziynvYaMO4QYYyUVsCUTpMN8juKNMHArhqIKkaj73LP%2FI861duCtka7wRjXE24T3CY9A3cJxdo%2FGvt%2FwLL5SXLxffFSxIFlukly3gZZ%2FH3wPLJW25pRSDGLRsjkp2Qjr4RWZ2Q5XQN9Ugv5ySdgpsQTJMpmHsw1IKXUaFN2StKUscf0BLff4X2M1QiOy3u3F5djcOl28%2FsuwszmmhvNBFnsqcbq2Xt%2BKj8uA%2FYdAoehr3TOOmGg8CHcV4b2x5opYVy3IQb3iC5nx79g%2FqiUmwVEo6XtjNH1lHja8zLIOrrq7OzXBYErDPc5btg2%2F3%2Ftu3oUnqXs9sCHHbCcWQrmnMCleHoYGgVLC794qE0oiRKo6cAVhN4Ha%2FTdRbA%2BjpcebjQyiHrjtIfgimpfB5mD%2F2RphiPc1b85gSirGOzPTs0nvyqm%2FR2BS2FPBL423DHFaPGd9IhlizKVlnYOH7dGkvyPpZ0Lpb05liW6zRNf1zUBsBBG4Y2htM9gT3nFaJSBqGbCcuDtxG2E%2FglefmxDvuvuw7X84I8u45%2F1taJ4jgUN4GcdzU3iD%2BddLLX%2F6byd8Sc3VW9JiblNlxE6EiA1k5P5prwejpLqRQKnb8yZu%2FQt7tvJWeCgs0N5wqoYvBQ0gYNPX61qpovPuPJPX%2BhjeKu%2B7b4zFXcfeDuTQTQbjMElVOZP0QYs9cdIN8FvZu30755v%2B9ZprNPjkl0XW%2F0k20%2FHnhsI3ROl2QIJ3ivBS%2BLOKuasWl%2FnFUQcNEHfJ7ifZjglIUh1MDLvvfiddFfDgEse3i%2B9s%2Bq%2FwnjYLar%2F1Nq0w6QDdNXesDbBfkINchnifEF%2BYRpZ%2B2xf8DeaPNnjKWfYyydYyyZZWyRfIKxoODSc8bovPA%2FduWfYBNXm4uu%2FgXz8Sg61wwAAA%3D%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var modalButtonWrapper = 'syrva1u';
export var modalCloseButton = 'syrva1i';
export var modalContentWrapper = _7a468({defaultClassName:'syrva1v',variantClassNames:{theme:{light:'syrva1w',dark:'he91700',darker:'he91700'}},defaultVariants:{},compoundVariants:[]});
export var modalDimmed = _7a468({defaultClassName:'syrva10',variantClassNames:{dimmed:{true:'syrva11',false:'syrva12'}},defaultVariants:{},compoundVariants:[]});
export var modalHeaderText = 'syrva1p';
export var modalSubTitle = _7a468({defaultClassName:'syrva1q',variantClassNames:{theme:{light:'syrva1r',dark:'syrva1s',darker:'syrva1t'}},defaultVariants:{},compoundVariants:[]});
export var modalTitle = _7a468({defaultClassName:'syrva1l',variantClassNames:{theme:{light:'syrva1m',dark:'syrva1n',darker:'syrva1o'}},defaultVariants:{},compoundVariants:[]});
export var modalTitleWrapper = 'syrva1j';
export var modalTitleWrapperInner = 'syrva1k';
export var modalTrigger = 'syrva1h';
export var modalWrapper = _7a468({defaultClassName:'syrva13',variantClassNames:{theme:{light:'syrva14',dark:'syrva15',darker:'syrva16'},gap:{'20':'syrva17','32':'syrva18','50':'syrva19'},width:{'460':'syrva1a','640':'syrva1b'},type:{modal:'syrva1c',alert:'syrva1d'},isOneButton:{true:'syrva1e'},fixedHeight:{true:'syrva1f'}},defaultVariants:{width:460,gap:32,type:'modal',theme:'dark'},compoundVariants:[[{type:'alert',isOneButton:true},'syrva1g']]});