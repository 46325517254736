import { create } from 'zustand';

type State = {
  alertTitle: string;
  alertDescription: string;
  alertConfirmedAction: (() => void) | null;
  confirmText?: string;
};

type Actions = {
  showAlert: (
    title: string,
    description: string,
    action?: () => void,
    confirmText?: string,
  ) => void;
  hideAlert: () => void;
  confirmAlert: () => void;
};

type Store = State & {
  actions: Actions;
};

const useAlertStore = create<Store>((set) => ({
  alertTitle: '',
  alertDescription: '',
  alertConfirmedAction: null,
  confirmText: '확인', // 초기값은 undefined

  actions: {
    showAlert: (title, description, action, confirmText) => {
      document.body.style.overflow = 'hidden';
      set({
        alertTitle: title,
        alertDescription: description,
        alertConfirmedAction: action,
        confirmText: confirmText || '확인', // confirmText가 없으면 기본값으로 '확인' 설정
      });
    },
    hideAlert: () => {
      document.body.style.overflow = '';
      set({
        alertTitle: '',
        alertDescription: '',
        alertConfirmedAction: null,
        confirmText: undefined,
      });
    },
    confirmAlert: () => {
      set((state) => {
        if (state.alertConfirmedAction) {
          state.alertConfirmedAction();
        }
        return {
          alertTitle: '',
          alertDescription: '',
          alertConfirmedAction: null,
          confirmText: undefined,
        };
      });
      document.body.style.overflow = '';
    },
  },
}));

export const useAlertActions = () => useAlertStore((state) => state.actions);
export const useAlertTitle = () => useAlertStore((state) => state.alertTitle);
export const useAlertDescription = () => useAlertStore((state) => state.alertDescription);
export const useConfirmText = () => useAlertStore((state) => state.confirmText);
