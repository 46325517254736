'use client';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  useAlertActions,
  useAlertDescription,
  useAlertTitle,
  useConfirmText,
} from '@/_global/_components/Alert/_stores/alertStore';
import { Modal } from '@/_global/_components/Modal/Modal';
import { modalDimmed, modalWrapper } from '@/_global/_components/Modal/modal.css';

export const Alert = () => {
  const alertTitle = useAlertTitle();
  const alertDescription = useAlertDescription();
  const confirmText = useConfirmText();
  const { confirmAlert, hideAlert } = useAlertActions();

  if (!alertTitle) {
    return null;
  }

  return ReactDOM.createPortal(
    <Modal.Root type={'alert'} isOneButton={window.innerWidth < 500}>
      <div className={modalDimmed({ dimmed: false })} />
      <div className={modalWrapper({ theme: 'dark', gap: 32, width: 460, type: 'alert' })}>
        <Modal.Header title={alertTitle} subTitle={alertDescription} onClose={() => hideAlert()} />
        <Modal.Footer confirmText={confirmText} onConfirm={confirmAlert} />
      </div>
    </Modal.Root>,
    document.body,
  );
};
