import { getCookie, setCookie } from 'cookies-next';

export const COOKIE_MAX_AGE = 60 * 60 * 24;

export const createDeviceId = () => {
  const result = [];
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < 20; i++) {
    result.push(chars.charAt(Math.floor(Math.random() * chars.length)));
  }
  return result.join('');
};

export const getDeviceId = () => {
  if (!getCookie('device_id')) {
    setCookie('device_id', createDeviceId(), {
      maxAge: COOKIE_MAX_AGE,
    });
  }
  return getCookie('device_id');
};
