import * as CPL from '@teamsparta/cross-platform-logger';

export function initCPL() {
  CPL.initCPLog(
    process.env.NEXT_PUBLIC_FLOUNDER_KEY!,
    process.env.NEXT_PUBLIC_AMPLITUDE_KEY!,
    process.env.NEXT_PUBLIC_HACKLE_SDK_KEY!,
    process.env.NEXT_PUBLIC_ENV !== 'release',
  );
}
