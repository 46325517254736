import { useEffect, useRef, useState } from 'react';
import { useFetchIsLoggedIn } from '@teamsparta/auth-frontend';
import { usePathname } from 'next/navigation';
import { LOGIN_WHITE_LIST } from '@/login/_domain/_data/login.model';
import { useQueryClient } from '@tanstack/react-query';

export const useIsLoggedIn = () => {
  const query = useFetchIsLoggedIn();
  const { isLoading, isSuccess, data } = query;
  const { is_logined, noToken } = data || {};
  const isLoggedIn = isSuccess && !noToken && is_logined;

  return { isLoading, isLoggedIn };
};

export const useShowLoginProcess = () => {
  const { isLoggedIn, isLoading } = useIsLoggedIn();
  const [isShow, setIsShow] = useState(false);
  const queryClient = useQueryClient();
  const wasLoggedIn = useRef(isLoggedIn);
  const pathname = usePathname();

  // URL 변경 시 로그인 상태 refetch
  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['isLoggedIn'],
    });
  }, [pathname, queryClient]);

  useEffect(() => {
    if (isLoading) return;

    const isJustLoggedOut = wasLoggedIn.current && !isLoggedIn;

    if (isJustLoggedOut) {
      // 로그아웃 된 경우 리프레시
      window.location.reload();
    } else {
      // 처음부터 로그인이 안 된 경우 모달 표시
      setIsShow(true);
    }

    wasLoggedIn.current = isLoggedIn;
  }, [isLoading, isLoggedIn]);

  return {
    isLoading,
    isLoggedIn,
    isShow,
    setIsShow,
  };
};

export const useLoginWhiteList = () => {
  const current = usePathname();
  return LOGIN_WHITE_LIST.includes(current);
};
