import { RunningRtan } from '@/_global/_components/_higher_order/suspense/loading/RunningRtan/RunningRtan';
import { Blank } from '@/_global/_components/_higher_order/suspense/loading/Blank/Blank';
import { Empty } from '@/_global/_components/_higher_order/suspense/loading/Empty/Empty';
import { Spinner } from './Spinner/Spinner';

export const Loading = {
  르탄이: RunningRtan,
  스켈레톤: Blank,
  공백: Empty,
  스피너: Spinner,
};
